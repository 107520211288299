import React, { useRef }                   from 'react'
import { Footer, Head, PageContainerGrid } from '../components'
import { YearsProvider }                   from '../context'
import { Header }                          from '../features'
import { YearsContent }                    from './years-content'

const START_YEAR = 2006

const YearsTemplate = ({ pageContext: { years, images, playlists, hero } }) => {
  const tRef = useRef(null)

  // remove any years with an empty playlist
  const pl = playlists.filter(p => p.videos.length > 0)

  // parse/format hero content for YearsContent component
  const { heroText, heroImage } = hero
  const currentYear = new Date().getFullYear()
  const numberOfYears = currentYear - START_YEAR
  const ht = heroText.replace('%numberOfYears%', numberOfYears)

  return (
    <YearsProvider value={{ years }}>
      <Head title='Home' />
      <PageContainerGrid ref={tRef} rowcount={4}>
        <Header />
        <main>
          <YearsContent heroImageUrl={heroImage.url} heroText={ht} images={images} playlists={pl} />
        </main>
        <Footer tRef={tRef} />
      </PageContainerGrid>
    </YearsProvider>
  )
}

export default YearsTemplate
